<template>
  <div>
    <van-popup
      :style="{ height: '80%' }"
      class="round-popup"
      position="bottom"
      v-model="showSearch"
    >
      <van-nav-bar @click-left="closePopup" left-arrow title="搜索">
        <template #left>
          <van-icon name="cross" size="18" />
        </template>
      </van-nav-bar>
      <van-cell-group>
        <div class="van-cell van-field" style="overflow: inherit">
          <div class="van-cell__title van-field__label">
            <span>选择部门</span>
          </div>
          <div class="van-cell__value van-field__value">
            <div class="van-field__body">
              <treeselect
                :multiple="true"
                :normalizer="normalizer"
                :options="options"
                placeholder="请选择"
                v-model="dept_ids"
              />
            </div>
          </div>
        </div>
        <field-date label="起始月份" type="year-month" v-model="begin_month" />
        <field-date label="截止月份" type="year-month" v-model="end_month" />
      </van-cell-group>

      <div class="btn">
        <van-button @click="search" block round type="info">提交</van-button>
      </div>

      <!-- <el-date-picker
        :clearable="false"
        @change="getNewData"
        end-placeholder="结束月份"
        format="yyyy年MM月"
        range-separator="-"
        start-placeholder="开始月份"
        style="margin-left:20px"
        type="monthrange"
        v-model="key"
        value-format="yyyy-MM"
      ></el-date-picker>-->
    </van-popup>

    <van-button
      @click="showSearch = true"
      class="btn-search"
      icon="search"
      size="small"
      type="info"
    />
  </div>
</template>
<script>
import { monthFormat } from '@/unit/unit.js'
import FieldDate from '@/components/FieldDate.vue'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    FieldDate,
    Treeselect,
  },
  data() {
    return {
      key: [monthFormat(), monthFormat()],
      dept_ids: [],
      listData: [],
      showSearch: false,
      begin_month: monthFormat(),
      end_month: monthFormat(),

      options: [],
      normalizer(node) {
        return {
          id: node.pid,
          label: node.name,
          children: node.children,
        }
      },
    }
  },
  mounted() {
    this.$axios.post('/dept/lists').then((res) => {
      this.options = res.data.data
    })
  },
  methods: {
    closePopup() {
      this.showSearch = false
    },
    search() {
      this.$parent.dept_ids = this.dept_ids
      this.$parent.key = [this.begin_month, this.end_month]

      this.$parent.getListData()
      this.showSearch = false
    },
  },
}
</script>
<style lang="less" scoped>
.btn-search {
  position: fixed;
  right: 20px;
  bottom: 20px;
  min-width: auto;
}
.van-popup:before {
  display: none;
}
/deep/ .vue-treeselect__control {
  padding: 0;
  border: none;
  border-radius: 0;
  height: inherit;
  .vue-treeselect__placeholder,
  .vue-treeselect__single-value {
    line-height: inherit;
    padding: 0;
    color: #9c9d9f;
  }

  .vue-treeselect__multi-value-item {
    padding: 0;
  }
  .vue-treeselect__multi-value-item-container {
    padding-top: 0;
  }
  .vue-treeselect__multi-value-item {
    border: none;
  }
}

/deep/ .vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 0;
  height: 24px;
}
</style>